
import { mapGetters } from 'vuex'
export default {
    props: {
        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            exchangeRates: 'theme2/settings/exchangeRate',
        }),
    },
}


import { mapGetters, mapState } from 'vuex'
export default {
    name: 'DownloadBanner',
    data: () => ({
        hostname: null,
        countdown: 3,
    }),
    computed: {
        ...mapState({
            downloadInterval: (state) => state.settings.downloadInterval,
        }),
        ...mapGetters({
            headerLogo: 'cms/headerLogo',
        }),
    },
    mounted() {
        this.hostname = window.location.hostname
        this.startCountdown()
    },
    methods: {
        startCountdown() {
            // Update the countdown every second
            const countdownInterval = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--
                } else {
                    // Countdown is finished, clear the interval
                    this.$store.commit('settings/setDownloadInterval', false)
                    clearInterval(countdownInterval)
                }
            }, 1000) // Update every second (1000 milliseconds)
        },
        closeBanner() {
            this.countdown = 0
            this.$store.commit('settings/setDownloadInterval', false)
        },
    },
}

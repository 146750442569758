import { render, staticRenderFns } from "./LoginForm.vue?vue&type=template&id=29402856"
import script from "./LoginForm.vue?vue&type=script&lang=js"
export * from "./LoginForm.vue?vue&type=script&lang=js"
import style0 from "./LoginForm.vue?vue&type=style&index=0&id=29402856&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PhoneNumber: require('/var/app/components/PhoneNumber.vue').default})
